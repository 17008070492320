<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = withDefaults(
  defineProps<{
    title: string
    description: string
    icon?: {
      path: string
      color: string
    }
    stickyHead?: boolean
    showConfirm?: boolean
    showCancel?: boolean
    confirmLabel?: string
    cancelLabel?: string
    textMarkup?: boolean
    onConfirm?: () => Promise<void>
    onCancel?: () => Promise<void>
  }>(),
  {
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    showConfirm: true,
    showCancel: true,
    textMarkup: false,
    stickyHead: false,
  }
)

const emit = defineEmits<{
  (event: 'hide'): void
  (event: 'loading', value: boolean): void
}>()

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
    emit,
  })

function hide() {
  emit('hide')
}
</script>

<template>
  <div class="relative" :class="{ 'bg-inherit': stickyHead }">
    <div
      class="p-5"
      :class="[stickyHead ? 'sticky top-0 bg-inherit' : 'relative']"
    >
      <div class="flex flex-col items-center">
        <base-icon
          v-if="icon"
          :svg="icon.path"
          class="mb-[15px]"
          :class="icon.color"
          :size="50"
        />
        <h2 class="w-72 text-center text-xl text-neutral-800">
          {{ title }}
        </h2>
      </div>

      <div class="absolute right-5 top-1/2 h-8 -translate-y-1/2 transform">
        <base-button
          icon="heroicons/outline/x-mark"
          :size="32"
          :disabled="loading"
          @click="hide"
        />
      </div>
    </div>
    <div>
      <div
        class="p-5 text-neutral-500"
        :class="{
          'text-center': !textMarkup,
          markup: textMarkup,
        }"
      >
        <div v-html="description" />
      </div>
      <div class="border-t p-5">
        <div
          v-if="showConfirm || showCancel"
          :class="{ 'grid grid-cols-2 gap-2': showConfirm && showCancel }"
        >
          <base-button
            v-if="showCancel"
            v-analytics:click="{
              cta: 'Cancel',
              pageGroup: 'Confirm Modal',
              page: props.title,
            }"
            :look="showConfirm ? 'outlined-color' : 'filled'"
            class="w-full"
            :disabled="loading"
            :loading="cancelling"
            @click="onCancel"
          >
            {{ cancelLabel }}
          </base-button>
          <base-button
            v-if="showConfirm"
            v-analytics:click="{
              cta: 'Confirm',
              pageGroup: 'Confirm Modal',
              page: props.title,
            }"
            class="w-full"
            :disabled="loading"
            :loading="confirming"
            @click="onConfirm()"
          >
            {{ confirmLabel }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
